<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create Role
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="roleCreateForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Role*"
                label-for="h-role-role"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  vid="role"
                  rules="required"
                >
                  <b-form-input
                    id="h-role-role"
                    v-model="role"
                    placeholder="Role Name"
                    :state="(errors.length > 0 || roleValidation) ? false : null"
                    name="role"
                    @input="roleValidation == true ? roleValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="roleValidation"
                    class="text-danger"
                  >
                    {{ roleError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Modules"
                vid="modules"
                rules="required"
              >
                <b-form-group
                  label="Modules*"
                  label-for="h-role-modules"
                  label-cols-md="3"
                  :state="(errors.length > 0 || moduleValidation) ? false : null"
                >
                  <v-select
                    id="h-address-modules"
                    v-model="modules"
                    label="title"
                    :options="moduleOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="moduleValidation == true ? moduleValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="moduleValidation"
                    class="text-danger"
                  >
                    {{ moduleError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Redirect URL"
                label-for="h-role-redirectURL"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Redirect URL"
                  vid="redirectURL"
                  rules=""
                >
                  <b-form-input
                    id="h-role-redirectURL"
                    v-model="redirectURL"
                    placeholder="/appointments/calendar"
                    :state="(errors.length > 0 || redirectURLValidation) ? false : null"
                    name="redirectURL"
                    @input="redirectURLValidation == true ? redirectURLValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="redirectURLValidation"
                    class="text-danger"
                  >
                    {{ redirectURLError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-9">
              <b-form-group
                label="Description*"
                label-for="h-role-description"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  vid="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="h-role-description"
                    v-model="description"
                    placeholder="Description"
                    rows="3"
                    :state="(errors.length > 0 || descriptionValidation) ? false : null"
                    name="description"
                    @input="descriptionValidation == true ? descriptionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="descriptionValidation"
                    class="text-danger"
                  >
                    {{ descriptionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-9">
              <validation-provider
                #default="{ errors }"
                name="Permissions"
                vid="permissions"
                rules="required"
              >
                <!-- <b-form-group
                  label="Permissions*"
                  label-for="h-role-permissions"
                  label-cols-md="3"
                  :state="(errors.length > 0 || permissionValidation) ? false : null"
                >
                  <v-select
                    id="h-role-permissions"
                    v-model="permissions"
                    label="name"
                    multiple
                    :options="permissionOptions"
                    :reduce="name => name._id"
                    @input="permissionValidation == true ? permissionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="permissionValidation"
                    class="text-danger"
                  >
                    {{ permissionError }}
                  </small>
                </b-form-group> -->
                <b-form-group
                  label="Permissions"
                  label-cols-md="2"
                  :state="(errors.length > 0 || permissionValidation) ? false : null"
                >
                  <div
                    v-for="(mainKey, index) in permissionOptions"
                    :key="index"
                  >
                    <div
                      v-for="(options, secondIndex) in mainKey"
                      :key="secondIndex"
                      class="mt-50"
                    >
                      <h5>
                        {{ secondIndex }}
                      </h5>
                      <div class="demo-inline-spacing ml-3">
                        <b-form-checkbox
                          v-for="(option, key) in options"
                          :key="key"
                          v-model="permissions"
                          :value="option._id"
                          name="permissions"
                          :disabled="option.disable"
                          @change="checkForDisability($event, index, secondIndex, key, option)"
                        >
                          {{ option.name }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'directory-roles-index' }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Save Role</span>
              </b-button>
            </li>
          </b-nav>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BFormTextarea, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      role: '',
      modules: 'Operations',
      redirectURL: '',
      description: '',
      permissions: [],
      roleError: 'Valid Role Name is required',
      roleValidation: false,
      moduleError: 'Valid Module is required',
      moduleValidation: false,
      descriptionError: 'Valid Description is required',
      descriptionValidation: false,
      permissionError: 'Valid permissions is required',
      permissionValidation: false,
      redirectURLError: 'Valid redirect URL is required',
      redirectURLValidation: false,
      moduleOptions: [
        { title: 'Appointment', code: 'Appointment' },
        { title: 'Operations', code: 'Operations' },
        { title: 'Inventory', code: 'Inventory' },
        { title: 'Purchasing', code: 'Purchasing' },
        { title: 'Budgeting', code: 'Budgeting' },
        { title: 'Directory', code: 'Directory' },
        { title: 'Dashboard', code: 'Dashboard' },
      ],
      permissionOptions: [],

      // validation rules
      required,
    }
  },
  created() {
    this.$http.get('directory/permissions')
      .then(response => {
        this.permissionOptions = response.data.permissions ?? []
        // console.log(response.data.permissions)
        // response.data.permissions.forEach(mainKey => {
        //   mainKey[0].forEach(option => {
        //     // eslint-disable-next-line no-param-reassign
        //     option.disabled = true
        //   })
        // })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    checkForDisability(event, firsrtIndex, secondIndex, key, option) {
      if (option.exceptions && option.exceptions.length) {
        if (event.length && event.includes(option._id)) {
          this.permissionOptions[firsrtIndex][secondIndex].forEach((data, dIndex) => {
            if (option.exceptions.includes(data.action)) {
              // eslint-disable-next-line no-param-reassign
              data.disable = true

              this.permissions = this.permissions.filter(item => item !== data._id)
              this.permissionOptions[firsrtIndex][secondIndex].splice(dIndex, 1, data)
              this.permissionOptions.splice(firsrtIndex, 1, this.permissionOptions[firsrtIndex])
            }
          })
        } else {
          this.permissionOptions[firsrtIndex][secondIndex].forEach((data, dIndex) => {
            if (option.exceptions.includes(data.action)) {
              // eslint-disable-next-line no-param-reassign
              data.disable = false
              this.permissionOptions[firsrtIndex][secondIndex].splice(dIndex, 1, data)
              this.permissionOptions.splice(firsrtIndex, 1, this.permissionOptions[firsrtIndex])
            }
          })
        }
      }
    },
    submitForm() {
      this.$refs.roleCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('role', this.role)
          formData.append('modules', this.modules)
          formData.append('description', this.description)
          formData.append('redirectURL', this.redirectURL)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.permissions.length; i++) {
            formData.append('permissions[]', this.permissions[i])
          }

          this.$http.post('directory/roles/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message ?? '',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'directory-roles-index' })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'role') {
                    this.roleError = validationError.msg
                    this.roleValidation = true
                  } else if (validationError.param === 'modules') {
                    this.moduleError = validationError.msg
                    this.moduleValidation = true
                  } else if (validationError.param === 'description') {
                    this.descriptionError = validationError.msg
                    this.descriptionValidation = true
                  } else if (validationError.param === 'redirectURL') {
                    this.redirectURLError = validationError.msg
                    this.redirectURLValidation = true
                  } else if (validationError.param === 'permissions') {
                    this.permissionError = validationError.msg
                    this.permissionValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
